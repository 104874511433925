import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Badge, Button } from 'react-bootstrap';
import { DataManager } from '../../core/data-manager';
import { ApiManager } from '../../core/api-manager';
import { LanguageProvider } from '../../core/language-provider';
import { LoginManager } from '../../core/login-manager';
import moment from 'moment';
import ImportXmlMetron from '../components/ImportXmlMetron';
import ImportXml3Cad from '../components/ImportXmlCad';



export default function InstanceCompilerTopHook({ data, entity = null, setDataKeyValue }) {

    const userInfo = LoginManager.getUserInfo();
    const location = useLocation();

    useEffect(() => {
        if (entity == 'customer_order') {
            var rows = data.rows
            if (rows && rows.length > 0 && data.orderDate && data.orderDate !== null) {
                var maxDate = moment(data.orderDate)
                for (let i = 0; i < rows.length; i++) {
                    var r = rows[i]
                    if (r.idSupplier !== null) {
                        if (r.idSupplier.deliveryTime) {
                            var tempDate = moment(data.orderDate, "YYYY-MM-DD").add(r.idSupplier.deliveryTime, 'days').format("YYYY-MM-DD")
                            if (moment(tempDate) > maxDate) {
                                maxDate = moment(tempDate)
                            }
                        }
                    }
                }
                if (data.deliveryDate !== moment(maxDate).format("YYYY-MM-DD")) {
                    setDataKeyValue('deliveryDate', moment(maxDate).format("YYYY-MM-DD"))
                }
            }
        }

        if (entity === 'customer_offer') {
            console.warn(data)
            if (data.idCustomer && data.idCustomer !== null) {
                if (data.idPaymentCondition !== data.idCustomer.idPaymentCondition) {
                    setDataKeyValue('idPaymentCondition', data.idCustomer.idPaymentCondition)
                }
            }
            if (data.rows.length > 0) {
                var count = 0;
                for (let i = 0; i < data.rows.length; i++) {
                    if(data.rows[i].price !== "0"){
                        count++;
                    }
                    var totale = data.rows.reduce((acc, item) => {
                        return acc + (parseFloat(item.quantity) * parseFloat(item.price))
                    }, 0)
                }
                const totale1 = parseFloat(totale);
                console.log(count);
                if(count !== 0 && data.totalPrice !== totale1){
                    setDataKeyValue('totalPrice', totale1)
                }
            }
        }

        if (entity === 'customer_order') {
            console.warn(data)
            if (data.idCustomer && data.idCustomer !== null) {
                if (data.idPaymentCondition !== data.idCustomer.idPaymentCondition) {
                    setDataKeyValue('idPaymentCondition', data.idCustomer.idPaymentCondition)
                }
                if (data.deliveryAddress === "" && data.deliveryAddress !== data.idCustomer.address) {
                    setDataKeyValue('deliveryAddress', data.idCustomer.address);
                    setDataKeyValue('latitude', data.idCustomer.latitude);
                    setDataKeyValue('longitude', data.idCustomer.longitude);
                }
            }
            if (data.rows.length > 0) {
                var count = 0;
                for (let i = 0; i < data.rows.length; i++) {
                    if(data.rows[i].price !== "0"){
                        count++;
                    }
                    var totale = data.rows.reduce((acc, item) => {
                        return acc + (parseFloat(item.quantity) * parseFloat(item.price))
                    }, 0)
                }
                const totale1 = parseFloat(totale);
                console.log(count);
                if(count !== 0 && data.totalPrice !== totale1){
                    setDataKeyValue('totalPrice', totale1)
                }
            }
        }

        if (entity === 'supplier_order') {
            console.warn('MARCO', data, location)
            if (data.idSupplier && data.idSupplier !== null) {
                if (data.idPaymentCondition !== data.idSupplier.idPaymentCondition) {
                    setDataKeyValue('idPaymentCondition', data.idSupplier.idPaymentCondition)
                }
            }
            if (data.file && data.file !== null) {
                var field = document.getElementById("supplier_order_field_confirmedDeliveryDate").querySelector("input");
                field.required = true
            }
            if (data.idCustomerOrder && data.idCustomerOrder !== null && data.idCustomerOrder.idCustomer) {
                if (data.customerName !== data.idCustomerOrder.idCustomer.name) {
                    setDataKeyValue('customerName', data.idCustomerOrder.idCustomer.name)
                }
            }
            if (location.pathname === '/entity/supplier_order/edit') {
                var field = document.getElementById("supplier_order_field_rows");
                if (field) {
                    field.remove()
                }
            }
        }

        if (entity === 'customer_offer_row') {
            //console.warn(data)
            if (data.listPrice && data.listPrice > 0) {
                var d1 = data.discount || 0
                var d2 = data.discountPercentage || 0
                var t = data.listPrice - ((data.listPrice * 1) * (data.discountPercentage * 1) / 100)
                t = t - data.discount
                var f = t * parseFloat(data.quantity)
                if (data.price !== f) {
                    setDataKeyValue('price', f)
                }
            }
        }

        if (entity === 'customer_order_row') {
            //console.warn(data)
            if (data.listPrice && data.listPrice > 0) {
                var d1 = data.discount || 0
                var d2 = data.discountPercentage || 0
                var t = data.listPrice - ((data.listPrice * 1) * (data.discountPercentage * 1) / 100)
                t = t - data.discount
                var f = t * parseFloat(data.quantity)
                if (data.price !== f) {
                    setDataKeyValue('price', f)
                }
            }
        }

        if (entity === 'customer') {
            //console.warn(data)
            if (data.address && data.address.length > 0) {

            }
        }

        if (entity === 'notification') {

            console.warn(data)
            if (data.notificationAll === 0) {
                if (data.notificationOrder !== 0) {
                    setDataKeyValue('notificationOrder', 0);

                }
                if (data.notificationOffer !== 0) {
                    setDataKeyValue('notificationOffer', 0);

                }
            }


        }

    }, [data]);



    if (entity !== null) {
        return (
            <>
                {(() => {
                    switch (entity) {
                        case 'sample': return (
                            <span>Test</span>
                        );
                        case 'customer_order': return (
                            <>
                                <Container fluid className='text-end mb-2'>

                                    <ImportXmlMetron data={data} entity='customer_order' setDataKeyValue={setDataKeyValue} />
                                    <ImportXml3Cad data={data} entity='customer_order' setDataKeyValue={setDataKeyValue} />
                                </Container>
                            </>

                        );
                        case 'customer_offer': return (
                            <>
                                <Container fluid className='text-end mb-2'>
                                    <ImportXmlMetron data={data} entity='customer_offer' setDataKeyValue={setDataKeyValue} />
                                    <ImportXml3Cad data={data} entity='customer_offer' setDataKeyValue={setDataKeyValue} />
                                </Container>
                            </>

                        );
                        default: return (
                            <></>
                        );
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};