import React from 'react';
import { Form} from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import FieldTooltip from '../../../components/Entity/FieldTooltip';
import FileField from '../../../components/Fields/File';
import ExternalEntity from '../../../components/Fields/ExternalEntity';
import MultipleExternalEntity from '../../../components/Fields/MultipleExternalEntity';
import DeepExternalEntity from '../../../components/Fields/DeepExternalEntity';
import RichText from '../../../components/Fields/RichText';
import Cropper from '../../../components/Fields/Cropper';
import PhoneInput from '../../../components/Fields/PhoneInput';
import AddressInput from '../../../components/Fields/AddressInput';
import Signature from '../../../components/Fields/Signature';
import CodeEditor from '../../../components/Fields/CodeEditor';


export default function Field({ type, fieldKey, value = null, entity, view, save, data }) {


    if (entity !== null) {

        return (
            <>

                {view.properties.columns[fieldKey].options && view.properties.columns[fieldKey].options.isNewLine ? (
                    <>
                        <div className='isNewLine-field' style={{flexBasis: '100%', borderTop: '0.5px solid lightgray', marginTop: 20, marginBottom: 20}}></div>
                    </>
                ) : (
                    <></>
                )}

                {(() => {
                    
                    var idField = entity + "_field_" + fieldKey;
                    var className = "mb-4 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2";
                    var fieldClassName = "field__" + type;
                    var label = LanguageProvider.getLabel(entity, fieldKey);
                    if(view.properties.columns[fieldKey].validation) {
                        if(view.properties.columns[fieldKey].validation.required === true) {
                            label = label + " *"
                        }
                    }

                    switch (type) {
                        case 'string': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={value !== null ? value : ''}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        maxLength={view.properties.columns[fieldKey].validation.maxLength}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'text': return (
                            <div className="mb-4 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={value !== null ? value : ''}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        maxLength={view.properties.columns[fieldKey].validation.maxLength}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'phone': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <PhoneInput value={value} entity={entity} fieldKey={fieldKey} update={save} />
                                </Form.Group>
                            </div>
                        );

                        case 'address': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <AddressInput value={value} entity={entity} fieldKey={fieldKey} update={save} view={view} />
                                </Form.Group>
                            </div>
                        );

                        case 'password': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={value}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'date': {
                            return (
                                <Form.Group className={className} id={idField} data-field-name={fieldKey}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={value}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                    />
                                </Form.Group>
                            )
                        };
                        
                        case 'time': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="time"
                                        value={value}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'datetime': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>{label}</Form.Label>
                                    <Form.Control
                                        type="datetime-local"
                                        value={value}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'number': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={value}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                        min={view.properties.columns[fieldKey].validation.min ? view.properties.columns[fieldKey].validation.min : null}
                                        max={view.properties.columns[fieldKey].validation.max ? view.properties.columns[fieldKey].validation.max : null}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'float': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        value={value}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                        min={view.properties.columns[fieldKey].validation.min ? view.properties.columns[fieldKey].validation.min : null}
                                        max={view.properties.columns[fieldKey].validation.max ? view.properties.columns[fieldKey].validation.max : null}
                                    />
                                </Form.Group>
                            </div>
                        );

                        case 'currency': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        value={value}
                                        required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                        onChange={(event) => save(fieldKey, event.target.value)}
                                        min={view.properties.columns[fieldKey].validation.min ? view.properties.columns[fieldKey].validation.min : null}
                                        max={view.properties.columns[fieldKey].validation.max ? view.properties.columns[fieldKey].validation.max : null}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'color': return (
                            <div className={className} id={idField}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control type="color" className="form-control-color" value={value} onChange={(event) => save(fieldKey, event.target.value)} />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'boolean': return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label={''}
                                        onChange={(event) => save(fieldKey, + event.target.checked)}
                                        checked={parseInt(value) || 0}
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                        case 'externalEntity': {
                            let field = view.properties.columns[fieldKey].options.labelField;
                            let itemEntity = view.properties.columns[fieldKey].options.entity;
                            var required = view.properties.columns[fieldKey].validation.required === true ? true : false
                            
                            return (
                                <div className={className} id={idField} data-field-name={fieldKey}>
                                    <ExternalEntity parentEntity={entity} parentItem={data} entity={itemEntity} update={save} value={value} externalField={field} field={fieldKey} label={label} required={required} />
                                </div>
                            )
                        }
                        
                        case 'multipleExternalEntity': {
                            let field = view.properties.columns[fieldKey].options.labelField;
                            let itemEntity = view.properties.columns[fieldKey].options.entity;
                            
                            return (
                                <div className={className} id={idField} data-field-name={fieldKey}>
                                    <MultipleExternalEntity parentEntity={entity} parentItem={data} entity={itemEntity} update={save} value={value} externalField={field} field={fieldKey} label={label} />
                                </div>
                            )
                        }
                        
                        case 'deepExternalEntity': {
                            let field = view.properties.columns[fieldKey].options.labelField;
                            let itemEntity = view.properties.columns[fieldKey].options.entity;
                            
                            return (
                                <div className="mb-4 col-sm-12 col-md-12" id={idField} data-field-name={fieldKey}>
                                    <DeepExternalEntity parentEntity={entity} parentItem={data} entity={itemEntity} update={save} value={value} externalField={field} field={fieldKey} label={label} />
                                </div>
                            )
                        }
                        
                        case 'option': {
                            var options = view.properties.columns[fieldKey].options.options;
                            return (
                                <div className={className} id={idField} data-field-name={fieldKey}>
                                    <Form.Group className={fieldClassName}>
                                        <Form.Label className='small'>
                                            {label}
                                            <FieldTooltip view={view} fieldKey={fieldKey} />
                                        </Form.Label>
                                        <Form.Select 
                                            required={view.properties.columns[fieldKey].validation.required === true ? true : false}
                                            onChange={(event) => save(fieldKey, {'value': event.target.value, 'label': event.target.value})} 
                                            value={value !== null && value.value}
                                        >
                                            <option value=''>Seleziona</option>
                                            {options.map((option, w) => {
                                                var optionVPkey = fieldKey + '.options.' + option.value
                                                return (
                                                    <option key={w} value={option.value}>{LanguageProvider.getLabel(entity, optionVPkey)}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            )
                        };
                        
                        case 'richText': return (
                            <div className="mb-4 col-sm-12 col-md-12" id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <RichText value={value} entity={entity} fieldKey={fieldKey} update={save} />
                                </Form.Group>
                            </div>
                        );

                        case 'code': {
                            let options = view.properties.columns[fieldKey].options;
                            return (
                                <div className="mb-4 col-sm-12 col-md-12" id={idField} data-field-name={fieldKey}>
                                    <Form.Group className={fieldClassName}>
                                        <Form.Label className='small'>
                                            {label}
                                            <FieldTooltip view={view} fieldKey={fieldKey} />
                                        </Form.Label>
                                        <CodeEditor value={value} entity={entity} fieldKey={fieldKey} update={save} options={options} />
                                    </Form.Group>
                                </div>
                            )
                        };
                        
                        case 'file': {
                            let options = view.properties.columns[fieldKey].options;
                            let validation = view.properties.columns[fieldKey].validation;
                            if(typeof options !== 'undefined' && options.crop === true) {
                                return (
                                    <div className={className + ' field_input_file_cropper'} id={idField} data-field-name={fieldKey}>
                                        <Cropper entity={entity} label={label} field={fieldKey} options={options} validation={validation} value={value} update={save} />
                                    </div>
                                )
                            }
                            else if(typeof options !== 'undefined' && options.signature === true) {
                                return (
                                    <div className={className + ' field_input_file'} id={idField} data-field-name={fieldKey}>
                                        <Signature entity={entity} label={label} field={fieldKey} options={options} validation={validation} value={value} update={save} />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className={className + ' field_input_file'} id={idField} data-field-name={fieldKey}>
                                        <FileField entity={entity} label={label} field={fieldKey} options={options} validation={validation} value={value} update={save} />
                                    </div>
                                )
                            }
                        };
                        
                        default: return (
                            <div className={className} id={idField} data-field-name={fieldKey}>
                                <Form.Group className={fieldClassName}>
                                    <Form.Label className='small'>
                                        {label}
                                        <FieldTooltip view={view} fieldKey={fieldKey} />
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={value !== null ? value : ''} 
                                        onChange={(event) => save(fieldKey, event.target.value)} 
                                    />
                                </Form.Group>
                            </div>
                        );
                        
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};